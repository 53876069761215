// src/components/Projects/ProjectsSection.jsx
import React from 'react';
import ProjectCard from './ProjectCard';
import { Terminal, LineChart, Layout } from 'lucide-react';

export const projects = [
  {
    title: "Federated Learning Platform UX",
    company: "Enolink",
    description: "Led UX design for a complex federated learning application, making data science collaboration more intuitive while maintaining sophisticated functionality.",
    icon: <Terminal className="w-6 h-6 text-indigo-400" />,
    examples: [
        {
          title: "User Journey Mapping",
          description: "Facilitated collaborative design sprints to map researcher workflows and identify key interaction points in the federated learning process.",
          image: "/images/journey-map.png",
          details: [
            "Cross-functional workshops with data scientists and researchers",
            "Identified and resolved critical user friction points",
            "Mapped complex multi-stakeholder workflows",
            "Established clear user task flows across system touchpoints"
          ]
        },
        {
          title: "Cohorts Overview",
          description: "Designed an intuitive interface for data scientists to prepare cohorts for model training across federated nodes.",
          image: "/images/CohortsStates.png",
          details: [
            "Clean, scannable table format that prioritizes the information researchers need most",
            "75% faster cohort creation",
            "Immediate insight into study progress",
            "Privacy-preserving data insights"
          ]
        },
        {
          title: "Cohort Configuration Interface",
          description: "What previously took weeks of database queries and spreadsheet manipulation now happens in minutes.",
          image: "/images/02-CohortBegin.png",
          details: [
            "Integrated controls allow immediate refinement of inclusion/exclusion criteria",
            "Flexible criteria adjustment with instant visual feedback",
            "Immediate validation of study population characteristics",
            "Standardized protocol enforcement"
          ]
        },
        {
          title: "Cohort Builder Demo",
          description: "Walkthrough of key features",
          isVideo: true,
          videoUrl: "https://drive.google.com/file/d/1ot-kaJcI8yprSv8OeoyXzx1CiibiptU2/preview", // Replace with your video ID
          aspectRatio: '56.25%',
          details: [
            
          ]
        }
      ]
  },
  {
    title: "Multi-Platform Lead Generation",
    company: "QuoteWizard",
    description: "Optimized conversion rates through strategic funnel design across multiple devices, implementing and testing improvements with cross-functional teams.",
    icon: <LineChart className="w-6 h-6 text-indigo-400" />,
    examples: [
      {
        title: "White Label and Branding",
        description: "Lead capture forms designed for brand flexibility",
        image: "/images/qw-v2.png",
        details: [
          "Marketing logic for key CSS variables",
          "Dynamic CSS templating",
          "Maintain testing frameworks",
          "Fast track new branded partnerships"
        ]
      },      {
        title: "Mobile-First Form Design",
        description: "Redesigned lead capture forms optimized for mobile conversion.",
        image: "/images/qw-v0.png",
        details: [
          "Progressive form completion",
          "Smart input validation",
          "Performance optimization",
          "Cross-device consistency"
        ]
      },
      {
        title: "A/B Testing Framework",
        description: "Established systematic testing process for continuous optimization.",
        image: "/images/qw-v1.png",
        details: [
          "Multivariate testing setup",
          "Analytics integration",
          "Real-time quote refresh",
          "ROI tracking"
        ]
      }
    ]
  },
  {
    title: "Domain Portfolio Platforms",
    company: "Endurance International",
    description: "Led the evolution of multi-brand marketing platforms, streamlining promotional campaign workflows and domain marketplace experiences while improving lead quality across properties.",
    icon: <Layout className="w-6 h-6 text-indigo-400" />,
    examples: [
      {
        title: "Domain Search Experience",
        description: "Reimagined domain discovery and purchasing workflow.",
        image: "/images/bd-results-cart.png",
        details: [
          "Intelligent search suggestions",
          "Price transparency",
          "Streamlined checkout",
          "Integration with multiple registrars"
        ]
      },
      {
        title: "Coupon Management Interface",
        description: "Created an intuitive interface for marketing teams to manage promotional campaigns across multiple brands.",
        image: "/images/bd-coupons.png", // Update with your actual image path
        details: [
          "Unified dashboard for multi-brand coupon management",
          "Real-time validation and conflict detection",
          "Streamlined workflow for campaign creation",
          "Advanced filtering and search capabilities"
        ]
      },
      {
        title: "Lead Quality Optimization",
        description: "Redesigned the domain marketplace landing pages to capture higher-intent leads through strategic form design and clear value propositions.",
        image: "/images/bd-lander.png", // Update path to your image
        details: [
          "Streamlined form fields increased completion rates by 23%",
          "Clear value propositions improved lead qualification",
          "Mobile-first design enhanced conversion across devices",
          "A/B tested messaging improved lead intent signals"
        ]
      },
      {
        title: "Portfolio Management",
        description: "Created unified dashboard for domain portfolio management.",
        image: "/images/bd-internal.png",
        details: [
          "Bulk actions interface",
          "Analytics dashboard",
          "Automatic renewal management",
          "Transfer workflow optimization"
        ]
      }
    ]
  }
];

const ProjectsSection = ({ onProjectSelect, focusedProjectIndex }) => {
  return (
    <section aria-labelledby="projects-heading" className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <h2 id="projects-heading" className="text-2xl font-bold text-white mb-8">Featured Projects</h2>
      <div className="grid gap-6 lg:grid-cols-3"
      role="list"
      aria-label="Project list"
      >
        {projects.map((project, index) => (
          <ProjectCard
            key={project.title}
            project={project}
            onClick={onProjectSelect}
            isFocused={index === focusedProjectIndex}
            index={index}
          />
        ))}
      </div>
    </section>
  );
};


export default ProjectsSection;
